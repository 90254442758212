import React from 'react';
import QueueAnim from 'rc-queue-anim';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import parse from "html-react-parser"
import SEO from '../components/seo';
import { useStaticQuery, graphql } from "gatsby"

export default function Page2() {

  const { wpPage: {id, slug, title, content} } = useStaticQuery(graphql`
    query AboutQuery {
      wpPage(slug: {eq: "about-us"}) {
        id
        slug
        title
        content
      }
    }
  `)

  const getContent = (content) => {
    const startPharagraph = content.indexOf('<p>');
    const endPharagraph = content.indexOf('</p>');
    return parse(content.slice(startPharagraph, endPharagraph + 4));
  }

  return (
    <OverPack component="section" className="page-wrapper page2">
      <QueueAnim
        type="bottom"
        className="page text-center"
        leaveReverse
        key="page"
      >
        <h2 key="title">{parse(title)}</h2>
        <span key="line" className="separator" />
        <QueueAnim type="bottom" className="info-content" key="content">
          <p className="main-info" key="1">{getContent(content)}</p>
          <p className="main-info" key="2">{parse(title)}</p>
        </QueueAnim>
      </QueueAnim>
    </OverPack>);
}